// CSS Reset, comment out if not required or using a different module
@import 'minireset.css/minireset';

@import 'scss-config';

body {
  --ig-size: var(--ig-size-small); //infragistic 16 update fix
}

/* igx 17 fix */
igx-grid-toolbar-hiding /*, igx-grid-toolbar-exporter*/ {
  button {
    // height: 2.25rem;

    igx-icon {
      color: var(--colour-surfaces) !important;
    }
  }
}

a {
  text-decoration: none !important;
}

// :root {
//   // --h-ui-header: 5rem;

//   // --h-ui-sidebar: calc(100vh - var(--h-ui-header));
//   // --w-ui-sidebar-expanded: 16rem;
//   // --w-ui-sidebar-collapsed: 5rem;

//   // --h-ui-content: calc(100vh - var(--h-ui-header));

//   // --w-ui-content-full: 100vw;
//   // --w-ui-content-sidebar-collapsed: calc(100vw - var(--w-ui-sidebar-collapsed));
//   // --w-ui-content-sidebar-expanded: calc(100vw - var(--w-ui-sidebar-expanded));
// }

html,
body {
  //font-size: 16px;
}

body {
  color: var(--colour-greys);
  background-color: #f1f2f7;
}

body._dark {
  background-color: black !important;
}

// .ig-typography {
//   font-family: Calibri, Carlito, "PT Sans", "Trebuchet MS", "sans‑serif";
// }

// .igx-date-picker{
//   width:125%;
// }

.igx-date-picker,
.igx-drop-down__list {
  border: 1px solid var(--colour-primary);
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%) !important;
  border-radius: var(--radius) !important;
}

igx-grid-header {
  text-transform: capitalize;
  .igx-grid-th__title {
    opacity: 1;
    color: var(--colour-greys);
    text-align: center;
  }
}

.igx-dialog__window {
  border-radius: 0.25rem;
}
.igx-dialog__window-content {
  padding: 0;
}
.igx-dialog__window-title {
  padding: 1rem;
}

.igx-drop-down__list {
  border: 1px solid var(--colour-greys);
  border-radius: var(--radius);
}

/*FIX FONT*/
*:not(i) {
  //font-family: Calibri, Carlito, "PT Sans", "Trebuchet MS", "sans-serif" !important;
  //font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  // font-style: normal;
  // font-variant: normal;
  //font-size: 1rem;

  h5 {
    font-size: 2rem;
  }
}

a {
  color: var(--colour-palette-5);
}
a:hover {
  color: var(--colour-palette-4);
}

// /**/
// ::-webkit-scrollbar {
//   width: 0.625rem;
//   height: 0.625rem;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: var(--colour-palette-9);
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: var(--colour-palette-6);
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--colour-palette-7);
// }
// /**/

// @each $key, $value in $colour-map {
//   .text-#{$key} {
//     color: $value;
//   }
// }

.tap {
  cursor: pointer;
}

.btn- {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;

  &sm {
    @extend .btn-, .text-2xl;
    background-color: var(--colour-secondary);
    color: var(--colour-surface);
  }

  &xs {
    border-radius: var(--radius);
  }
}

.m- {
  &0 {
    margin: 0 !important;
  }
}

.btn-primary {
  color: var(--colour-surface) !important;
  background-color: var(--colour-palette-4) !important;
  border-color: var(--colour-palette-4) !important;
  border-radius: var(--radius);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: var(--colour-surface) !important;
  background-color: var(--colour-palette-4-dark) !important;
  border-color: var(--colour-palette-4-dark) !important;
}

.btn-cancel {
  background-color: var(--colour-default-800) !important;
  border-color: var(--colour-default-800) !important;
  color: var(--colour-surface) !important;
}
.btn-cancel:hover,
.btn-cancel:active,
.btn-cancel:focus {
  background-color: var(--colour-default-900) !important;
  border-color: var(--colour-default-900) !important;
  color: var(--colour-surface) !important;
}

.igx-grid-thead {
  height: 3rem;
}
.igx-grid-th__icons {
  align-self: center;
}
.igx-grid-th--compact {
  padding: 0 0.5rem;
}

.igx-grid__tbody-message {
  visibility: hidden;
}
.igx-grid__tbody-message:after {
  content: 'Enter your search in the top right';
  visibility: visible;
  display: block;
  position: absolute;
}

.text- {
  &xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  &xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  &2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  &3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  &4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  &5xl {
    font-size: 3rem;
    line-height: 1;
  }
  &6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  &7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  &8xl {
    font-size: 6rem;
    line-height: 1;
  }
  /*&9xl {
    font-size: 8rem;
    line-height: 1;
  }*/
}

label[igxLabel] {
  font-size: 1rem;
  font-weight: 600 !important;
  color: var(--colour-greys);
}

igx-simple-combo,
igx-input-group {
  margin: 1.5rem 0 !important;
}

.table-subheader-right {
  igx-input-group {
    margin: 0 !important;
  }
}

.igx-input-group__label {
  overflow: visible;
}

.igx-input-group--placeholder .igx-input-group__label,
.igx-input-group--filled .igx-input-group__label,
.igx-input-group--file .igx-input-group__label {
  transform: translateX(-0.75rem) translateY(-100%) /*scale(0.75)*/; //infragistic 16 update fix
}

.igx-input-group input {
  padding-top: 0.375rem; //infragistic 16 update fix
}

.igx-select__toggle-button,
.igx-combo__toggle-button {
  background-color: transparent !important; //infragistic 16 update fix
}

.igx-checkbox__composite {
  border-radius: var(--radius);
}

.igx-input-group__bundle {
  padding-top: 0.375rem;
}

.igx-combo__content > igx-display-container.igx-display-container,
igx-virtual-helper.igx-vhelper--vertical {
  height: 100% !important;
}

.container {
  height: var(--h-ui-grid) !important;
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.container-nb {
  height: var(--h-ui-content) !important;
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.igx-dialog {
  --border-color: var(--ig-dialog-border-color) !important;
}

.title-container {
  color: var(--colour-primary) !important;
  background-color: var(--colour-surface);
  border-top: solid;
  border-top-width: 0.1875rem;
  border-bottom: 1px solid #e0e0e0;
}

.dialog-title {
  display: flex;
}

.add-edit-dialog {
  min-width: 40vw;
  max-width: 60rem;
  min-height: 10vh;
  //max-height: 60vh;
  overflow: auto;

  form {
    padding: 0rem 1rem;
  }

  &.sml {
    min-height: 10vh;
    max-height: 40vh;
    //width: 30vw;
    display: block;
    padding: 0rem 1rem;
    form {
      padding: 0rem 0rem;
    }
  }

  &.sml-h {
    min-height: 9rem;
    max-height: 40vh;
    //width: 30vw;
    display: block;
    padding: 0rem 1rem;
    form {
      padding: 0rem 0rem;
    }
  }

  &.xl {
    min-height: 70vh;
    max-height: 85vh;
  }

  &.data-export {
    // min-height: 70vh;
    // max-height: 85vh;
    min-width: 60vw;
    max-width: 70vw;
  }

  &.bulk-upload {
    min-height: 10vh;
    min-width: 40vw;
    max-width: 100vw;
  }

  &.pos-prof {
    min-height: 50vh;
    max-height: 70vh;
    display: flex;

    > *:first-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .pp-tabs {
      flex: 1;
      overflow-y: scroll;

      igx-tabs {
        flex: 1;
      }

      .igx-tabs__header-item {
        border-top: 1px solid #e0e0e0;
      }
    }
  }

  &.add-project {
    display: flex;
    min-height: 65vh;
    max-height: 85vh;

    > *:first-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      & > *:first-child {
        flex: 1;
      }
    }

    igx-tabs {
      flex: 1;
    }

    .igx-tabs__header-scroll--start {
      justify-content: space-evenly;
    }

    .igx-tabs__header-item {
      flex: 1;
      height: 100%;
    }
  }
}

.export-dialog {
  // background-color: red;
  gap: 1rem;
  flex: 0 !important;
  height: 4rem !important;
}

.grid-containter {
  height: 100%;
}

.table-header {
  height: var(--h-ui-grid-button-section);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  //margin-bottom: 0.75rem;

  border-bottom: 1px solid #e0e0e0;
}
.table-header2 {
  height: var(--h-ui-grid-button-section);
}

.ui-page-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
}
.ui-page-footer-onebtn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
}

.table-subheader-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  align-self: center;
  margin-left: 1rem;
}

.table-subheader-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  align-self: center;
  margin-right: 1rem;
}

//ignored
// span[igx-grid-th__title] {
//   line-height: 1.25rem!important;
// }
.igx-grid-th__title {
  line-height: 1rem !important;
  span {
    font-size: 0.9rem !important;
    white-space: normal;
  }
}

.igx-grid__td-text {
  white-space: normal;
}

//ignored
// .igx-grid-th__title {
//   line-height: 1rem !important;
// }

/*COPIED FROM EVALEX*/
.-radius- {
  &full {
    border-radius: var(--radius) !important;
  }
  &left {
    border-radius: var(--radius) 0 0 var(--radius) !important;
  }
  &right {
    border-radius: 0 var(--radius) var(--radius) 0 !important;
  }
  &top {
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }
  &bottom {
    border-radius: 0 0 var(--radius) var(--radius) !important;
  }
  &tl {
    border-radius: var(--radius) 0 0 0 !important;
  }
  &tr {
    border-radius: 0 var(--radius) 0 0 !important;
  }
  &br {
    border-radius: 0 0 var(--radius) 0 !important;
  }
  &bl {
    border-radius: 0 0 0 var(--radius) !important;
  }
}

// .iti__flag {background-image: url("path/to/flags.png");}

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {background-image: url("path/to/flags@2x.png");}
// }

/*CUSTOM STYLING*/
.card {
  @extend .-radius-full;
  background-color: var(--colour-surface);

  &-primary {
    border-top: 3px solid var(--colour-primary);
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  //border: 1px solid var(--colour-greys-100);
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);

  .card-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0.5rem 0.75rem;

    // border-bottom: 1px solid var(--colour-greys-100);
    background-color: var(--colour-surface) !important;

    h5 {
      margin: 0 !important;
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--colour-primary);
    }
  }

  .card-body {
    padding: 0.75rem;

    font-size: 0.875rem;
  }
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.grid-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    //width: 75vw;
    //display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //gap: 1rem 2rem;
    margin-top: 2rem;
  }
}

.ody-sel-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

//CDN Changes
// .sidebar-custom {
//   padding: 0.3125rem 0.5rem !important;
// }
// .sidebar-mini .main-sidebar .nav-link,
// .sidebar-mini-md .main-sidebar .nav-link,
// .sidebar-mini-xs .main-sidebar .nav-link {
//   // width: calc(250px - 0.25rem - 0.5rem * 2) !important;
// }
// .nav-link {
//   padding: 0.5rem 0.75rem !important;
//   display: flex !important;
//   flex-direction: row !important;
// }
// .nav-link i.nav-icon {
//   width: 1.75rem !important;
//   margin-left: 0 !important;
//   text-align: left !important;
//   height: 1.875rem !important;
//   line-height: 1.875rem !important;
//   vertical-align: middle !important;
// }
// .nav-sidebar .nav-header {
//   font-size: 1rem !important;
// }
// .nav-item.ml-4 .nav-link {
//   width: calc(250px - 0.25rem - 1.5rem - 0.5rem * 2) !important;
// }
// body.sidebar-collapse aside:not(:hover) .nav-item {
//   i.nav-icon {
//     text-align: center !important;
//   }
//   margin-left: 0 !important;
//   .nav-link {
//     width: calc(3.6rem - 0.25rem) !important;
//     p {
//       display: none;
//     }
//   }
// }
// body.sidebar-collapse aside:hover {
//   .sidebar-mini .main-sidebar .nav-link,
//   .sidebar-mini-md .main-sidebar .nav-link,
//   .sidebar-mini-xs .main-sidebar .nav-link {
//     // width: calc(250px - 0.25rem - 0.5rem * 2) !important;
//   }
//   .nav-link {
//     padding: 0.5rem 0.75rem !important;
//     display: flex !important;
//     flex-direction: row !important;

//     p {
//       white-space: normal !important;
//       width: 90% !important;
//     }
//   }
//   .nav-link i.nav-icon {
//     width: 1.75rem !important;
//     margin-left: 0 !important;
//     text-align: left !important;
//     height: 1.875rem !important;
//     line-height: 1.875rem !important;
//     vertical-align: middle !important;
//   }

//   .nav-item.ml-4 .nav-link {
//     width: calc(250px - 0.25rem - 1.5rem - 0.5rem * 2) !important;
//   }
// }
.igx-button {
  color: var(--colour-surface) !important;
  background-color: var(--colour-palette-4) !important;
  border-color: var(--colour-palette-4) !important;
  border-radius: var(--radius);
}

igx-input-group {
  margin: 0rem;
}

/* LOGIN PAGES STYLING */
.big-top-margin {
  margin-top: 7.5rem;
  margin-bottom: 1rem;
}

@media screen and (max-height: 600px) {
  .big-top-margin {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.panel-float {
  text-align: center;
  width: 28.5rem; // 400px;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  margin: 0 auto;
  padding: 1rem; //15px
  box-shadow: 0 0 1rem 0.07rem rgba(100, 100, 100, 0.5);
  -webkit-box-shadow: 0 0 1rem 0.07rem rgba(100, 100, 100, 0.5);
  -moz-box-shadow: 0 0 1rem 0.07rem rgba(100, 100, 100, 0.5);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border: 0.07rem solid #ccc;
  box-sizing: border-box;
}

.logo-container {
  margin: 1rem 0 2rem 0;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    //height: 7.1rem;
    border-width: 0;
    max-height: 5.5rem;
  }
}

.top-margin {
  margin-top: 1rem !important;
}

.bottom-margin {
  margin-top: 1rem;
}

.error-message {
  //display: flex;
  background-color: var(--colour-warn);
  border-color: var(--colour-warn-dark);
  color: var(--colour-palette-1);
  padding: 0.75rem;
  position: relative;
  border-radius: var(--radius);
  text-align: center;
}

.info-message {
  background-color: var(--colour-success);
  border-color: var(--colour-success-dark);
  color: var(--colour-palette-1);
  padding: 0.75rem;
  position: relative;
  border-radius: var(--radius);
  text-align: center;
}

.otpMessage {
  text-align: center;
  color: var(--colour-greys);
}

.resendMessage {
  line-height: 1.5rem !important;
  font-size: 0.85rem !important;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  color: var(--colour-greys);
}

.text-box-input {
  -webkit-tap-highlight-color: transparent;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  display: block;
  line-height: 1.45rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-shadow: inset 0 0 0 1.5rem #f3f3f3;
  padding: 0.35rem 1rem;
  width: 100%;
  border: 0;
  height: 2.85rem;
  font-size: 1rem;
  border-bottom: 0.07rem solid #eee;
  margin-bottom: 0.7rem;
  border-radius: 0.2rem;
}

.button-big-login {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  font: inherit;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  background-image: none;
  user-select: none;
  padding: 0.7rem 1.15rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: 0.07rem solid transparent;
  text-decoration: none;
  color: #fff;
  background-color: var(--colour-primary);
  border-color: var(--colour-primary);
  width: 100%;
  border-radius: 0.2rem;

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.button-big-secondary {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  font: inherit;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  background-image: none;
  user-select: none;
  padding: 0.7rem 1.15rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: 0.07rem solid transparent;
  text-decoration: none;
  color: #fff;
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  width: 100%;
  border-radius: 0.2rem;

  &:disabled {
    opacity: 0.5;
  }
}

.resend-otp {
  color: var(--colour-palette-1) !important;
  background-color: var(--colour-default-300) !important;
  width: 50% !important;

  &:disabled {
    opacity: 0.65;
    box-shadow: none;
    border: 0;

    padding: calc(0.375rem + 1px);
  }
}

.forgotpw {
  font-size: 1rem;
  color: var(--colour-primary) !important;
}

.ms-logo {
  width: 1.5rem;
}

.panel-mobile {
  width: 320px;
}

.instructions {
  p {
    color: var(--colour-greys);
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
}

/* TOOLTIP STYLING*/

[ev-data-tooltip] {
  &:hover {
    position: relative;
    &::after {
      all: initial;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 0.625rem solid var(--colour-primary);
      position: absolute;
      content: '';
      left: calc(50% - 0.625rem);
      transform: rotate(180deg);
      top: calc(100%);
    }
    &::before {
      all: initial;
      font-family: inherit;
      display: inline-block;
      border-radius: var(--radius);
      padding: 0.625rem;
      background-color: var(--colour-primary);
      color: #f9f9f9;
      position: absolute;
      width: 7.125rem;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      font-size: 1rem;
      top: calc(100% + 0.5rem);
      content: attr(ev-data-tooltip);
    }
  }
  &:disabled {
    &::before {
      opacity: 1 !important;
    }
    &::after {
      opacity: 1 !important;
    }
  }
}

@media(max-width: 800px)or (max-height: 600px) {
  .is-small-screen-hide-table-cell {
    display:none !important
    // display: table-cell !important;
  }
}

@media((max-width: 800px) or (max-height: 600px))and ((hover: none) or (pointer: coarse)) {
  .is-mobile-hide-table-cell {
    display:none !important
  }
}


//overrides cdn styles
button.close {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.alert-warning {
  color: #1f2d3d;
  background-color: #ffbe4b;
  border-color: #ffb532;
}

.alert-warning {
  color: #1f2d3d;
  background-color: #ffbe4b;
  border-color: #ffb532;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
}

.close, .mailbox-attachment-close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.alert .close, .alert .mailbox-attachment-close {
  color: #000;
  opacity: .2;
}

.alert .close:hover,.alert .mailbox-attachment-close:hover {
  opacity: .5
}

.alert-info {
  color: #1f2d3d;
  background-color: #61c2d5;
  border-color: #4dbad0;
}

.alert-success {
  color: #1f2d3d;
  background-color: #a5d264;
  border-color: #9acc50;
}

h5 .btn {
  display: inline-block !important;
  font-weight: 400 !important;
  color: #212529 !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: .25rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.card {
  box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
  margin-bottom: 1rem
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 700;
}

.igx-days-view__date--selected .igx-days-view__date-inner {
  color: var(--date-selected-hover-foreground) !important;
}

